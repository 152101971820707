import { Fragment, memo } from 'react';
import isEqual from 'react-fast-compare';
import {
  StyledMainCategoryContent,
  StyledL2CategoryWithL3LinksColumn,
  StyledLink,
  StyledSpan,
} from './CategoryDropdownMiddle.styled';
import { Category } from 'types/Category';

interface CategoryDropDownMiddleProps {
  category?: Category;
  className?: string;
  show?: boolean;
  toggleCategoryDropDown?: () => void;
}

const CategoryDropDownMiddle = ({ category, className, show, toggleCategoryDropDown }: CategoryDropDownMiddleProps) => {
  const getCategoryLink = (category: Category, isL2 = false) => {
    const name = category?.name;
    const clickable = category?.clickable;
    const qualifier = category?.qualifier;
    const url = category?.url;

    if (!name) {
      return null;
    }

    if (!clickable) {
      return isL2 ? <StyledSpan isHead>{name}</StyledSpan> : name;
    }

    return (
      <StyledLink url={qualifier ? `/${qualifier}` : url} isHead={isL2} onClick={toggleCategoryDropDown}>
        {category.name}
      </StyledLink>
    );
  };

  return (
    <StyledMainCategoryContent className={className} show={show} tipsAndInfoCategory={category?.tipsAndInfoStyling}>
      {category?.subcategoryList?.map((l2Category) => {
        return (
          <StyledL2CategoryWithL3LinksColumn key={l2Category.code}>
            {getCategoryLink(l2Category, true)}
            {l2Category?.subcategoryList?.map((l3Category) => {
              return <Fragment key={l3Category.code}>{getCategoryLink(l3Category)}</Fragment>;
            })}
          </StyledL2CategoryWithL3LinksColumn>
        );
      })}
    </StyledMainCategoryContent>
  );
};

export default memo(CategoryDropDownMiddle, isEqual);
