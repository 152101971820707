interface coordinate {
  x: number;
  y: number;
}

const DELAY = 300;

const slope = (a: coordinate, b: coordinate) => (b.y - a.y) / (b.x - a.x);

const getOffset = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  return {
    left: rect.left,
    top: rect.top,
  };
};

const getAnchorPoints = (menuElement: HTMLElement) => {
  const offset = getOffset(menuElement);
  const upperLeft = {
    x: offset.left,
    y: offset.top,
  };
  const upperRight = {
    x: offset.left + menuElement.offsetWidth,
    y: upperLeft.y,
  };
  const lowerLeft = {
    x: offset.left,
    y: offset.top + menuElement.offsetHeight,
  };
  const lowerRight = {
    x: offset.left + menuElement.offsetWidth,
    y: lowerLeft.y,
  };

  return {
    lowerLeft,
    lowerRight,
    offset,
    upperLeft,
    upperRight,
  };
};

type Config = {
  delay?: number;
};

/**
 * based on https://github.com/kamens/jQuery-menu-aim
 */

type CalculateDelayParams = {
  config?: Config;
  menuElement?: HTMLElement;
  positions: coordinate[];
};

const calculateDelay = ({ config, menuElement, positions }: CalculateDelayParams) => {
  if (menuElement) {
    const { lowerRight, offset, upperRight } = getAnchorPoints(menuElement);

    const loc = positions[positions.length - 1];
    let prevLoc = positions[0];

    if (!loc) {
      return 0;
    }

    if (!prevLoc) {
      prevLoc = loc;
    }

    if (prevLoc.x < offset.left || prevLoc.x > lowerRight.x || prevLoc.y < offset.top || prevLoc.y > lowerRight.y) {
      // If the previous mouse location was outside of the entire
      // menu's bounds, immediately activate.
      return 0;
    }

    const decreasingCorner = upperRight,
      increasingCorner = lowerRight;

    const decreasingSlope = slope(loc, decreasingCorner),
      increasingSlope = slope(loc, increasingCorner),
      prevDecreasingSlope = slope(prevLoc, decreasingCorner),
      prevIncreasingSlope = slope(prevLoc, increasingCorner);

    if (decreasingSlope < prevDecreasingSlope && increasingSlope > prevIncreasingSlope) {
      // Mouse is moving from previous location towards the
      // currently activated submenu. Delay before activating a
      // new menu row, because user may be moving into submenu.
      return config?.delay ?? DELAY;
    }

    return 0;
  }

  return 0;
};

export { calculateDelay, getAnchorPoints, slope };
