import { RefObject, useEffect, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { calculateDelay } from 'utils/menuUtil';

type Config = {
  delay?: number;
  maxPositionsTracked?: number;
};

type Coordinate = {
  x: number;
  y: number;
};

const useMouseMoveRightDelay = (ref: RefObject<HTMLDivElement>, config?: Config) => {
  const [positions, setPositions] = useState<Coordinate[]>([]);
  const mouseEnterTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const addPosition = (position: Coordinate) => {
    setPositions((positions: Coordinate[]) => {
      const newPositions = [...positions, position];
      if (newPositions.length > (config?.maxPositionsTracked ?? 10)) {
        newPositions.shift();
      }
      return newPositions;
    });
  };

  useEventListener(
    'mousemove',
    (event: MouseEvent) => {
      addPosition({ x: event.x, y: event.y });
    },
    ref,
  );

  const onMouseEnter = (callback: () => void) => {
    if (mouseEnterTimeout.current) {
      clearTimeout(mouseEnterTimeout.current);
    }

    const activationDelay = calculateDelay({
      config: { delay: config?.delay ?? undefined },
      menuElement: ref.current ?? undefined,
      positions,
    });

    if (activationDelay) {
      mouseEnterTimeout.current = setTimeout(() => {
        callback();
      }, activationDelay);
    } else {
      callback();
    }
  };

  const onMouseLeave = () => {
    if (mouseEnterTimeout.current) {
      clearTimeout(mouseEnterTimeout.current);
    }
  };

  useEffect(
    () => () => {
      if (mouseEnterTimeout.current) {
        clearTimeout(mouseEnterTimeout.current);
      }
    },
    [],
  );

  return { onMouseEnter, onMouseLeave };
};

export default useMouseMoveRightDelay;
