import styled, { css } from 'styled-components';
import { breakpointUp, fontSize, spacer, color, variable } from 'theme';
import { BREAKPOINTS } from 'constants/breakpoints';
import Link from 'components/Link/Link';

interface StyledCategoryDropDownMiddleProps {
  show?: boolean;
  tipsAndInfoCategory?: boolean;
}

interface StyledLinkProps {
  isHead?: boolean;
}

export const StyledMainCategoryContent = styled.div<StyledCategoryDropDownMiddleProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  background-color: ${color('whisper-100')};
  overflow: hidden;
  padding-top: ${spacer(125)};
  padding-left: ${spacer(125)};
  border-radius: ${variable('border-radius')};

  column-count: ${({ tipsAndInfoCategory }) => (tipsAndInfoCategory ? 2 : 3)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css<StyledCategoryDropDownMiddleProps>`
      column-count: ${({ tipsAndInfoCategory }) => (tipsAndInfoCategory ? 3 : 4)};
    `
  )}

  ${breakpointUp(
    BREAKPOINTS.LG,
    css<StyledCategoryDropDownMiddleProps>`
      column-count: ${({ tipsAndInfoCategory }) => (tipsAndInfoCategory ? 4 : 5)};
    `
  )}
`;

const LinkStyle = css<StyledLinkProps>`
  display: inline-block;
  padding: ${spacer(25)} ${spacer(100)};
  width: 100%;
  font-size: ${fontSize(88)};
  font-weight: ${({ isHead }) => (isHead ? 'bold' : 'normal')};
  overflow-wrap: break-word;
  hyphens: manual;
  && {
    color: ${({ isHead }) => (isHead ? color('primary-400') : color('black'))};
  }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  ${LinkStyle}

  && {
    &:hover {
      color: ${color('primary-300')};
    }
  }
`;

export const StyledSpan = styled.span<StyledLinkProps>`
  ${LinkStyle}
`;

export const StyledL2CategoryWithL3LinksColumn = styled.div`
  padding-bottom: ${spacer(200)};
  break-inside: avoid-column;
`;
