import styled, { css } from 'styled-components';
import { zIndex, specific, variable, breakpointUp, color, spacer } from 'theme';
import { BREAKPOINTS } from '../../../../../constants/breakpoints';
import CategoryDropDownLeft from '../CategoryDropDownLeft/CategoryDropDownLeft';

const LEFT_WRAP_WIDTH = '300px';
const LEFT_WRAP_WIDTH_DESKTOP = '350px';

interface StyledCatDropDownProps {
  show: boolean;
}

export const StyledDropDownWrapper = styled.div<StyledCatDropDownProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  z-index: ${zIndex('header')};
  overflow: hidden;
  background-color: ${color('white')};
  color: ${specific('dropdown', 'dropdown-text-color')};
  padding: ${spacer(100)} ${spacer(125)} ${spacer(125)} ${spacer(125)};
  border-bottom-left-radius: ${variable('border-radius')};
  border-bottom-right-radius: ${variable('border-radius')};
`;

export const StyledCategoryDropDownLeft = styled(CategoryDropDownLeft)`
  min-width: ${LEFT_WRAP_WIDTH};

  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      min-width: ${LEFT_WRAP_WIDTH_DESKTOP};
    `
  )}
`;

export const StyledCategoryDropDownRight = styled.div`
  min-height: auto;
  max-height: 75vh;
  overflow: auto;
  width: calc(100% - ${LEFT_WRAP_WIDTH});

  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      width: calc(100% - ${LEFT_WRAP_WIDTH_DESKTOP});
    `
  )};
`;

export const StyledCatDropDownClickDetector = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: ${zIndex('negative')};
`;
